#BackpackScreenContainer{
    background: url('./../../assets/images/background/bg-contest.png') center;
    background-size: cover;
    padding: 32px 24px 0 24px;
    position: relative;
}
.info_icon{
    cursor: pointer;
    width: 32px;
}
#animation_box{
    position: relative;
}

#backpack_bg_animation{
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0.15s;
}
.attempt_button{
    display: block;
    background: #fff;
    box-shadow: 0px 0px 12px rgba(41, 109, 159, 0.4);
    border-radius: 14px;   
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    color: #296D9F; 
    width: fit-content;
    padding: 12px 24px;
    margin: 8px auto;
    cursor: pointer;
}
.attempt_button:disabled{
    background: #FFFFFF;
    color: #788E9E;
}
.avatars_footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-height: 160px;
}

@media screen and (max-height: 600px) {
    #animation_box{
        width: 80%;
        margin: auto;
    }
    .attempt_button{
        font-size: 20px;
    }
}