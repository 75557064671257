#popover_container{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    bottom:50%;
    transform: translate(-50%, -5%);
    background-color: #fff;
    border-radius: 16px;
    padding: 18px 26px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
    text-align: center;
}
#popover_container.show{
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -10%);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
#popover_container:before {
  position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 25px);
    bottom: -5px;
    border-style: solid;
    border-width: 24px;
    border-color: #fff;
    transition-duration: 0.3s;
    transition-property: transform;
    border-radius: 7px;
    transform: rotate(45deg);
}
.popover_text{
    font-weight: 800;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
}
.gems_count{
    font-weight: 800;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
}
#popover_container .gems_icon {
    display: block;
    width: 100%;
    max-width: 40px;
    margin: auto;
}
#popover_container .web_icon {
    display: block;
    width: 100%;
    max-width: 32px;
    margin: auto;
}
#popover_container .prize_icon {
    display: block;
    width: 100%;
    max-width: 80px;
    margin: auto;
    margin-bottom: 8px;
}

#avatars_popover_container{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom:75%;
    transform: translate(0%, -5%);
    background-color: #fff;
    border-radius: 16px;
    padding: 10px 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
    text-align: center;
    max-width: 90%;
}
#avatars_popover_container.show{
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0%, -10%);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
#avatars_popover_container.position_right{
    right: 3%;
}
#avatars_popover_container.position_left{
    left: 3%;
}
#avatars_popover_container:before {
  position: absolute;
    z-index: -1;
    content: "";
    bottom: -5px;
    border-style: solid;
    border-width: 16px;
    border-color: #fff;
    transition-duration: 0.3s;
    transition-property: transform;
    border-radius: 7px;
    transform: rotate(45deg);
}
#avatars_popover_container.show.position_right:before{
    left: calc(70%);
}
#avatars_popover_container.show.position_left:before{
    right: calc(70%);
}
#avatars_popover_container .popover_text{
    font-size: 16px;
    font-weight: 600 !important;
}

#coins_popover_container{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom:95%;
    left:10%;
    transform: translate(0%, -5%);
    background-color: #fff;
    border-radius: 16px;
    padding: 18px 26px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
    text-align: center;
    max-width: 80%;
}
#coins_popover_container.show{
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0%, -10%);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
#coins_popover_container:before {
  position: absolute;
    z-index: -1;
    left: calc(50% - 16px);
    content: "";
    bottom: -5px;
    border-style: solid;
    border-width: 16px;
    border-color: #fff;
    transition-duration: 0.3s;
    transition-property: transform;
    border-radius: 7px;
    transform: rotate(45deg);
}
#coins_popover_container .popover_text{
    font-weight: 600 !important;
}
#coins_popover_container button{
    font-weight: 800;
font-size: 16px;
line-height: 130%;
}