#initialScreenContainer {
    background: url('./../../assets/images/background/bg-general-blue.png') center;
    background-size: cover;
    padding: 32px 24px 0 24px;
    position: relative;
}

#header_home {
    width: 100%;
    position: relative;
    padding: 0 24px 0;
}

.user_home_left_icon, .user_home_right_icon {
    position: absolute;
    top: 0;
}

.user_home_left_icon {
    left: -24px;
}

.user_home_right_icon {
    right: -24px;
}

#initialScreenTitle {
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    margin: 8px auto;
}

#initialScreenText {
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: rgba(39, 39, 39, 0.75);
    text-align: center;
}

#initialScreenList, #modalList, #screenList {
    max-width: 300px;
    margin: 16px auto;
}

#initialScreenList {
    max-height: 320px;
    overflow-y: scroll;
}

@media screen and (max-width:320px) {
    #initialScreenList {
        max-height: 160px;
    }
}

@media screen and (max-height:650px) {
    #initialScreenList {
        max-height: 200px;
    }
}

.list_element {
    display: flex;
    margin: 20px auto;
    align-items: flex-start;
}

#screenList .list_element {
    align-items: flex-start;
    margin-bottom: 16px;
}

.list_element_top {
    display: flex;
    margin: 40px auto;
    align-items: flex-start;
}

.list_image {
    width: 32px;
}

.list_element div {
    margin-left: 20px;
}

.list_element p, .list_element_top p {
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    color: rgba(39, 39, 39, 0.75);
}

.list_element h3, .list_element_top h3 {
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;

}

a {
    text-decoration: none;
}

#finalPageButton {
    width: fit-content;
    width: -webkit-fit-content;
    padding: 14px 24px;
    margin: auto;
    display: block;
}

#disabledButton {
    margin-top: 0;
    background: #5a5555;
    border: 2px solid #5a5555;
}

#openWheelButton {
    margin-top: 0;
}

#bottomButtonsInitialScreen {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
}

#boxSpan {
    font-weight: 600 !important;
}

#bottomButtonsInitialScreen button, #sessionCountdown {
    font-weight: 800 !important;
}


/*Web-APP SIZE on WEB*/

#modalTitle {
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
    color: #272727;
    text-align: center;
}

.modalLink {
    text-align: center;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    font-weight: 600;
    text-decoration-line: underline;
    color: #296D9F;
    display: block;
    width: 100%;
    margin-bottom: 8px;
}

#futureSessionBox {
    background: #fff;
    border-radius: 20px 20px;
    border: 4px solid #D95124;
}

#currentSessionBox {
    background: #fff;
    border-radius: 20px 20px;
    border: 4px solid #4F95CC;
}

#futureSessionBox button, #currentSessionBox button {
    margin-top: 0;
    transform: scale(1.025) translateY(3.3px);
}

#boxSpan {
    padding: 8px 16px;
    display: block;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
}

#sessionCountdown {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #D95124;
}
#sessionEndCountdown {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #296D9F;
}