#popover_container{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    bottom:45%;
    transform: translate(-50%, -5%);
    background-color: #fff;
    border-radius: 16px;
    padding: 18px 26px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
    text-align: center;
}
#popover_container.show{
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -10%);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
#popover_container:before {
  position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 25px);
    bottom: -5px;
    border-style: solid;
    border-width: 24px;
    border-color: #fff;
    transition-duration: 0.3s;
    transition-property: transform;
    border-radius: 7px;
    transform: rotate(45deg);
}
.popover_text{
    font-weight: 800;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
}
#avatar_popover_container{
    position: absolute;
    bottom:75%;
    transform: translate(0%, -5%);
    background-color: #fff;
    border-radius: 16px;
    padding: 10px 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
    text-align: center;
    max-width: 70%;
    right: 10px;
    z-index: 10002;
}
#avatar_popover_container:before {
  position: absolute;
    z-index: -1;
    content: "";
    bottom: -5px;
    border-style: solid;
    border-width: 16px;
    border-color: #fff;
    transition-duration: 0.3s;
    transition-property: transform;
    border-radius: 7px;
    transform: rotate(45deg);
    right:30%
}
.avatar_single{
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: radial-gradient(circle at 93% 6%, rgba(255, 255, 255, 0) 13%, rgba(0, 0, 0, 0.5) 18%);
    z-index: 10001;
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 20%, 77% 0);
}
.single_avatar_container{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    min-height: 370px;
}
.avatar_bottom{
    position: absolute;
    right: 0;
    bottom: 0;
}
#avatar_popover_container .popover_text{
    font-size: 16px;
    font-weight: 600 !important;
}