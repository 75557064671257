#coins_counter{
    color: #FFFFFF;
}
.coins_label{
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
}
.coins_count{
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
    display: flex;
}
.coins_count img{
    width: 24px;
    margin-right: 4px;
}
