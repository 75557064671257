#accordionContainer {
    padding: 0 8px;
    max-height: 305px;
    overflow-y: scroll;
    overflow-x: visible;
}

.accordion__item {
    margin: 12px auto;
}

.accordion__heading {
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(41, 109, 159, 0.4);
    border-radius: 16px;
    font-weight: 800;
    font-size: 16px;
    line-height: 130%;
    color: #272727;
    padding: 17px;

}

.accordion__heading .inline_icon {
    width: 20px;

}

.accordion__heading .accordion__button {
    display: flex;
    justify-content: space-between;
}

.accordion__heading .accordion__button .control_arrow {
    transition: 0.2s;
}

.accordion__heading .accordion__button[aria-expanded=true] .control_arrow {
    transform: rotate(180deg);
}

.accordion__panel {
    padding: 12px 17px;
    transition: 0.2s;
    font-weight: 600 !important;
}

.accordion__panel[hidden] {
    opacity: 0;
    transform: scaleY(0);
}

.accordion__panel {
    animation: showhide .3s ease-in-out;
    opacity: 1;
    transform: scaleY(1);
}

.accordion__panel a, .accordion__panel a:visited {
    text-decoration: underline;
    color: #296D9F;
    font-weight: 800;
}

@keyframes showhide {
    0% {
        opacity: 0;
    }

    1% {
        opacity: 0;
        transform: scaleY(0);
    }

    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}

.input-group {
    margin: 16px auto;
}

.input-group input {
    background: #FFFFFF;
    border: 4px solid #4F95CC;
    box-sizing: border-box;
    border-radius: 16px;
    font-size: 16px;
    line-height: 21px;
    padding: 16px;
    color: #272727;
    display: block;
    width: 100%;
    margin-top: 8px;
}

.input-group label {
    font-size: 16px;
    line-height: 21px;
    color: #63666A;
}
