@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');

body, button, * {
  font-family: 'Nunito';
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, p {
  color: #272727;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

body>#root {
  height: 100vh;
}

*::placeholder {
  color: rgba(39, 39, 39, 0.3);

}

@viewport {
  orientation: portrait;
}

#MainApp {
  width: 100%;
  z-index: 100;
  position: relative;
}

/*Web-APP SIZE on WEB*/
@media screen and (min-width: 769px) {
  #MainApp {
    max-width: 400px;
    margin: auto;

  }

  #root {
    position: relative;
    background-size: cover;
    z-index: 0;
  }

  #root::before {
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background: url('./assets/images/background/opacity.svg') center;
    background-size: cover;
    z-index: 1;
  }
}

/*General Style*/

.text-tf{
  color: #296D9F
}

.fw-800{
  font-weight: 800;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.inline_icon {
  width: 16px;
  vertical-align: middle;
}

button {
  width: 100%;
  text-align: center;
  padding: 12px 0;
  color: #fff;
  background: #296D9F;
  box-shadow: 0px 0px 12px rgba(41, 109, 159, 0.4);
  border-radius: 16px;
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  margin-top: 8px;
}

.grey_btn {
  background: #FFFFFF;
  color: #296D9F;
}

#openRewardButton[disabled] {
  background: #FFFFFF;
  color: #788E9E;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #272727af;
  z-index: 10001;
}

.loader::before {
  content: '';
  background-image: url(./assets/images/icons/loader.gif);
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 150px;
  max-height: 150px;
}

/*Bottom Modal*/
.modal-content {
  padding: 26px;
  max-height: 90vh;
  margin-bottom: 16px;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content.bottom_modal {
  opacity: 0;
  transform: translateY(100%);
  transition: all 500ms ease-in-out;
}

.ReactModal__Content--after-open.bottom_modal {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Content--after-close.bottom_modal {
  opacity: 0;
  transform: translateY(100%);
}

.w-100{
  width: 100%;
}

span img{
  vertical-align: sub;
}

/*Loader Inline*/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 14px;
}
.lds-ellipsis div {
  position: absolute;
  top: 0px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #296D9F;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}